import request from "@/utils/axios";

export function crowdManage(params) {
  return request.post("/crowd/getCrowdList", params);
}

export function handleCrowdDisplay(params) {
  return request.post("/crowd/changeDisplay", params);
}

export function deleteCrowd(params) {
  return request.post("/crowd/deleteCrowd", params);
}

export function getCrowdStatus(params) {
  return request.post("/crowd/getCreateCrowdStatus", params);
}

export function refreshCrowd(params) {
  return request.post("/crowd/getCrowd", params);
}

export function checkCrowd(params) {
  return request.post("/crowd/checkCreateCrowd", params);
}

<template>
  <div class="clearfix manageDiv">
    <tool-bar>
      <template #imgClick>
        <buttons-com
          @createCrowd="createCrowd('createFlag')"
          @idCreateCrowd="createCrowd('idCreateFlag')"
          @refresh="initData"
        />
      </template>
    </tool-bar>
    <el-table :data="tableData" stripe v-loading="tableLoading">
      <el-table-column type="expand">
        <template #default="{ row }">
          <div v-for="(items, i) in row.details" :key="i">
            <div v-for="item in items.attr" :key="item.name" class="expand">
              <div style="display: inline-block">
                <span>{{ item.name }}：</span>
              </div>
              <div style="display: inline-block; max-width: 95%">
                <el-tag v-for="label in item.value" :key="label">
                  {{ label }}
                </el-tag>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="crowdName" label="名称" />
      <el-table-column prop="createTimeStr" label="日期" />
      <el-table-column prop="creator" label="创建者" />
      <!--类别
      1.预定义
      2.用户标签
      3.用户ID包-->
      <el-table-column prop="srcTypeStr" label="类别" />
      <el-table-column prop="statusStr" label="状态">
        <template #default="scope">
          <el-button
            @click.prevent="getCrowdStatus(scope)"
            type="text"
            size="small"
            :disabled="scope.row.status === 1"
            v-text="scope.row.statusStr"
          />
        </template>
      </el-table-column>
      <el-table-column prop="isDelete" label="操作">
        <template #default="scope">
          <el-button
            @click.prevent="deleteRow(scope)"
            type="text"
            size="small"
            :disabled="!scope.row.isDelete"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="isDisplay" label="显示">
        <template #default="scope">
          <el-checkbox
            v-model="scope.row.isDisplay"
            :disabled="scope.row.isDisplay && judgeCheckBoxDisabled(scope)"
            @change="handleDisplay(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <create-crowd
      v-model="createFlag"
      @submit="submitDialogOpen"
      @refreshCrowd="initData"
    />
    <id-create-crowd
      v-model="idCreateFlag"
      @submit="submitDialogOpen"
      @refreshCrowd="initData"
    />
    <!--报错/进度-->
    <error-dialog v-model="errorDialogFlag" @confirmClick="closeErrorDialog">
      <progress-com v-if="progressFlag" :value="progressValue" />
      <p v-else v-html="errorDialogText"></p>
    </error-dialog>
    <!--删除人群对话框-->
    <error-dialog
      v-model="deleteDialogFlag"
      :cancel="true"
      @confirmClick="confirmDelete"
    >
      <p>确认删除人群？</p>
    </error-dialog>
  </div>
</template>

<script>
/* el-tab-pane是遍历出来的
 * 里面的table也是遍历出来的，根据传入的数据不同
 *
 * 是不是可以做成展开行
 *
 */
import toolBar from "@/components/toolBar";
import buttonsCom from "@/components/buttons/buttons";
import CreateCrowd from "./createCrowd/createCrowd";
import errorDialog from "@/components/errorDialog";
import progressCom from "./progress/progress";
import idCreateCrowd from "./idCreateCrowd/createCrowd";

import { mapGetters, mapMutations } from "vuex";
import {
  crowdManage,
  handleCrowdDisplay,
  deleteCrowd,
  getCrowdStatus,
  refreshCrowd,
  checkCrowd,
} from "@/api/crowdManage";

export default {
  name: "manage",
  data() {
    return {
      tableData: [],
      createFlag: false,
      errorDialogFlag: false,
      errorDialogText: "",
      deleteDialogFlag: false,
      deleteCrowd: {},
      progressFlag: false,
      progressValue: 0,
      timer: "",
      idCreateFlag: false,
      temp: false,
      tableLoading: false,
    };
  },
  computed: {
    ...mapGetters(["categorySeg"]),
  },
  mounted() {
    this.initData();
  },
  methods: {
    ...mapMutations({
      setCrowdList: "SET_CROWDLIST",
      setTargetCrowd: "SET_TARGETCROWD",
      setColTargetCrowd: "SET_COLTARGETCROWD",
    }),
    initData() {
      this.tableLoading = true;
      crowdManage().then((res) => {
        this.tableData = res[0].crowdList;
        this.refreshCrowd();
      });
    },
    refreshCrowd() {
      // 更新人群列表
      refreshCrowd().then((res) => {
        // 判断crowdList是否有变化？
        this.setCrowdList(res.crowdInfo.crowdList);
        // }
        // 判断目标人群不在人群列表中，不在的话取第一个作为setTargetCrowd
        let tempKeyArr = [];
        let tempArr = res.crowdInfo.crowdList.map((item) => {
          return item.key;
        });
        tempKeyArr = tempKeyArr.concat(tempArr);
        if (!tempKeyArr.includes(this.categorySeg)) {
          this.setTargetCrowd(res.crowdInfo.crowdList[0]);
          this.setColTargetCrowd(res.crowdInfo.crowdList[0]);
        }
        this.tableLoading = false;
      });
    },
    createCrowd(flag) {
      checkCrowd().then((res) => {
        if (res.isValid) {
          this[flag] = true;
        } else {
          this.errorDialogText = `新建人群数量限制。<br>您已创建的人群数量已经超过授予您可创建人群的最大数量${res.maxCrowd}。`;
          this.errorDialogFlag = true;
        }
      });
    },
    submitDialogOpen() {
      this.errorDialogText = "任务已提交";
      this.errorDialogFlag = true;
      this.progressFlag = false;
    },
    deleteRow(scope) {
      // 删除对话框
      this.deleteDialogFlag = true;
      this.deleteCrowd = {
        index: scope.$index,
        rows: scope.store.states.data,
        id: scope.row.id,
        segmentId: scope.row.segmentId,
      };
    },
    closeErrorDialog() {
      this.errorDialogFlag = false;
      this.initData();
    },
    // 确认删除
    confirmDelete() {
      this.deleteDialogFlag = false;
      this.deleteCrowd.rows.splice(this.deleteCrowd.index, 1);
      // 从远程删除数据
      let params = {
        id: this.deleteCrowd.id,
      };
      deleteCrowd(params).then((res) => {
        this.setCrowdList(res.crowdInfo);
        // 如果是删除操作  如果操作的是targetCrowd  则更新targetCrowd
        if (this.deleteCrowd.segmentId === this.categorySeg) {
          this.setTargetCrowd(res.crowdInfo[0].crowdList[0]);
          this.setColTargetCrowd(res.crowdInfo[0].crowdList[0]);
        }
        this.$message({
          type: "success",
          message: "删除人群成功!",
        });
      });
    },
    judgeCheckBoxDisabled(scope) {
      // 保证只剩下一个的时候不能disabled
      if (!scope.$index) {
        return true;
      } else {
        return false;
      }
    },
    handleDisplay(row) {
      // 告诉数据库更改数据，并会影响到targetcrowd
      // 传 id isdisplay
      let params = {
        id: row.id,
        isDisplay: row.isDisplay,
      };
      handleCrowdDisplay(params).then((res) => {
        this.setCrowdList(res.crowdInfo);
        // 如果是隐藏操作  如果操作的是targetCrowd  则更新targetCrowd
        if (!row.isDisplay && row.segmentId === this.categorySeg) {
          this.setTargetCrowd(res.crowdInfo[0].crowdList[0]);
          this.setColTargetCrowd(res.crowdInfo[0].crowdList[0]);
        }
      });
    },
    getCrowdStatus(scope) {
      this.errorDialogFlag = true;
      this.progressFlag = true;
      let params = {
        seg: scope.row.segmentId,
        month: scope.row.dataMonth,
      };
      getCrowdStatus(params).then((res) => {
        this.judgeStatus(res, scope);
      });
    },
    judgeStatus(res, scope) {
      console.log(res, scope);
      // 0 失败 1 成功 2 进度 3重名（未开发）
      clearTimeout(this.timer);
      if (res.status === 0) {
        this.progressFlag = false;
        this.progressValue = 0;
        this.errorDialogText =
          "匹配到的ID数量不满足人群创建最低人数要求，无法创建人群.";
      } else if (res.status === 1) {
        this.progressFlag = false;
        this.progressValue = 0;
        this.errorDialogText = "人群创建成功.";
      } else if (this.errorDialogFlag && res.status === 2) {
        this.timer = setTimeout(() => {
          if (!res.speed && this.progressValue < 20) {
            this.progressValue += 2;
          } else if (this.progressValue < res.speed) {
            this.progressValue = res.speed;
          } else if (this.progressValue < res.speed + 18) {
            this.progressValue += 2;
          }
          this.getCrowdStatus(scope);
        }, 5000);
      } else {
        this.errorDialogFlag = false;
        this.progressFlag = false;
      }
    },
  },
  watch: {
    errorDialogFlag(newVal) {
      if (!newVal) {
        clearTimeout(this.timer);
        this.progressValue = 0;
      }
    },
  },
  components: {
    buttonsCom,
    CreateCrowd,
    errorDialog,
    progressCom,
    idCreateCrowd,
    toolBar,
  },
};
</script>

<style scoped lang="scss">
.manageDiv {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;

  .expand {
    min-width: 51%;
    display: inline-flex;
    align-items: baseline;

    .el-tag {
      margin: 6px 3px;
      height: 22px;
      line-height: 20px;
      color: $color-sub-text;
    }
  }
}

.breadWrap {
  height: 40px;
  line-height: 40px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  top: 50px;
  left: 0;
  padding-left: 140px;
  vertical-align: middle;

  .box {
    width: 350px;
    display: flex;
    align-items: center;
  }
}

.el-breadcrumb {
  line-height: 41px;
  overflow: hidden;
  flex: 1;
  margin-left: 10px;
}

.breadIcon {
  font-size: 20px;
  width: 20px;
}
</style>

<template>
  <div class="toolBarDiv">
    <div style="flex: 1; display: flex; align-items: center;">
      <slot name="select"></slot>
      <slot name="checkBox"></slot>
    </div>
    <slot name="imgClick"></slot>
  </div>
</template>

<script>
export default {
  name: "toolBar",
};
</script>

<style scoped lang="scss">
.toolBarDiv {
  display: flex;
  align-items: center;
  padding-left:20px;
  padding-top:10px;
}
</style>

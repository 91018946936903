<template>
  <el-dialog
    v-model="flag"
    width="30%"
    custom-class="errorDialog"
    :title="title"
    @close="cancelClick"
    append-to-body
    destroy-on-close
  >
    <slot></slot>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="cancelClick" v-show="cancel">
          取 消
        </el-button>
        <el-button type="primary" @click="handlerClick" :disabled="disabled">
          确 定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
/*
 * v-model显示的flag，默认false
 * 是否显示取消按钮，默认不显示
 * slot插入中间显示的内容
 * */
export default {
  name: "error-dialog",
  data() {
    return {
      disabled: false,
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "提示",
    },
  },
  emits: ["update:modelValue", "confirmClick"],
  computed: {
    flag: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    cancelClick() {
      this.disabled = false;
      this.$emit("update:modelValue", false);
    },
    handlerClick() {
      this.disabled = true;
      this.$emit("confirmClick");
    },
  },
};
</script>

<style scoped></style>

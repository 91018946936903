<template>
  <div>
    <p>人群正在创建，请稍等…</p>
    <el-progress
      :text-inside="true"
      :stroke-width="width"
      :percentage="value"
    />
  </div>
</template>

<script>
export default {
  name: "progresscom",
  data() {
    return {
      width: 30,
    };
  },
  props: {
    value: {
      type: Number,
      default: 50,
    },
  },
};
</script>

<style scoped lang="scss"></style>

import request, { uploadFile } from "@/utils/axios";

export function getCrowdProperty(params) {
  return request.post("/crowd/getDemoF", params);
}

export function getInterest(params) {
  return request.post("/crowd/getInterestF", params);
}

export function createCrowd(params) {
  return request.post(`/crowd/createCrowd`, params);
}

export function checkCrowdName(params) {
  return request.post("/crowd/isExist", params);
}

export function idCreateCrowd(params) {
  return uploadFile("/crowd/uploadUserList", params);
}

export function getAllCrowd(params) {
  return request.post("/crowd/getAllCrowd", params);
}

<template>
  <el-dialog
    v-model="flag"
    :title="'导入人群'"
    :before-close="closeDialog"
    width="60%"
    custom-class="normalDialog"
    destroy-on-close
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      label-width="120px"
      class="createCrowdForm"
      ref="formData"
      :rules="rules"
    >
      <el-form-item label="人群名称" class="formSubmit" prop="name">
        <el-input v-model="formData.name" class="formInput" />
        <el-button
          size="small"
          type="primary"
          :disabled="disabled"
          @click="onSubmit('formData')"
        >
          创建
        </el-button>
      </el-form-item>
      <el-tabs type="card" v-model="activeTabName" class="formTab">
        <el-tab-pane label="ID文件">
          <el-form-item label="ID类型">
            <el-radio-group v-model="formData.fileType">
              <!-- <el-radio :label="6">MD5手机号</el-radio>
              <el-radio :label="7">手机号</el-radio>
              <el-radio :label="3">明文IMEI（14位）</el-radio>
              <el-radio :label="4">明文IMEI（15位）</el-radio> -->
              <el-radio :label="1">MD5 IMEI（14位）</el-radio>
              <el-radio :label="2">MD5 IMEI（15位）</el-radio>
              <el-radio :label="5">IDFA</el-radio>
              <el-radio :label="8">MID</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="ID文件" prop="file">
            <el-upload
              ref="upload"
              :action="action"
              :headers="{ token }"
              class="uploadDiv"
              :file-list="fileList"
              :limit="limit"
              :accept="accept"
              :before-upload="beforeUpload"
              :on-preview="handlePreview"
              :on-progress="uploading"
              :on-success="success"
              :on-error="error"
              :before-remove="beforeRemove"
              :on-remove="handleRemove"
            >
              <div>
                <img src="./upload.png" alt="" />
              </div>
              <span v-if="loadingMark" class="loadingI">
                <i class="el-icon-loading"></i>
              </span>
            </el-upload>

            <p class="annotation">
              * ID文件必须为txt格式，最大100M，每行一个ID.
            </p>
          </el-form-item>
          <el-form-item label="说明">
            <el-input v-model="formData.explain" style="width: 85%" />
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </el-dialog>
</template>

<script>
import { createCrowd, checkCrowdName } from "@/api/createCrowd";
import { mapGetters } from "vuex";

export default {
  name: "id-create-crowd",
  data() {
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入人群名称"));
      } else if (value.trim().indexOf(" ") !== -1) {
        return callback(new Error("人群名称中间不能有空格!"));
      }
      this.checkCrowdName(value, callback);
    };
    let validateFile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请先上传文件"));
      } else {
        callback();
      }
    };
    return {
      formData: {
        name: "",
        explain: "",
        fileType: 6,
        file: "",
      },
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        file: [{ validator: validateFile, trigger: "blur" }],
      },
      imgSrc: require("./add.png"),
      activeTabName: "0",
      idType: 1,
      action: process.env.VUE_APP_API_HOST + "/crowd/uploadUserList",
      fileList: [],
      disabled: false,
      // 上传文件时的标志
      loadingMark: false,
      limit: 1,
      accept: ".txt",
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "submit", "refreshCrowd"],
  computed: {
    ...mapGetters(["token"]),
    flag: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    checkCrowdName(name, callback) {
      let params = {
        name: name,
      };
      checkCrowdName(params).then((res) => {
        if (res.existCode) {
          callback(new Error("已存在相同名称人群"));
        } else {
          callback();
        }
      });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          createCrowd(this.formData).then(() => {
            // 刷新crowdList
            this.$emit("update:modelValue", false);
            this.$emit("submit");
            this.$emit("refreshCrowd");
          });
        } else {
          return false;
        }
      });
    },
    closeDialog() {
      // 关闭时看下是否在提交文件
      if (!this.disabled) {
        this.$refs.formData.resetFields();
        this.$refs.upload.clearFiles();
        this.formData.name = "";
        this.formData.explain = "";
        this.formData.fileType = 6;
        this.formData.file = "";
        this.$emit("update:modelValue", false);
      }
    },
    beforeUpload(file) {
      const lastIndex = file.name.lastIndexOf(".");
      const fileName = file.name.slice(0, lastIndex);
      const pattern = new RegExp(
        "[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？%]"
      );
      const includeBlank = fileName.indexOf(" ") === -1;
      if (!includeBlank) {
        this.$message({
          message: "上传文件名不能包含空格!",
          type: "warning",
        });
        return false;
      } else if (pattern.test(fileName)) {
        this.$message({
          message: "上传文件名只能包含中文、英文、数字、下划线",
          type: "warning",
        });
        return false;
      }
      // 对上传的文件进行限制
      const postfix = file.name.slice(lastIndex + 1);
      const extension = postfix === "txt";
      if (!extension) {
        this.$message({
          message: "上传文件只能是txt格式!",
          type: "warning",
        });
        return false;
      }
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        this.$message({
          message: "上传文件大小不能超过 100MB!",
          type: "warning",
        });
        return false;
      }
      return extension && isLt100M && includeBlank;
    },
    handlePreview(file) {
      // 点击文件
      console.log(file);
    },
    uploading() {
      // 上传中  不能点击创建  点击外部对话框不会消失
      this.loadingMark = true;
      this.disabled = true;
    },
    success(response, file, fileList) {
      // 上传成功
      if (response.status) {
        this.$message({
          message: "上传文件成功！",
          type: "success",
        });
        this.formData.file = encodeURIComponent(response.fileName);
        this.loadingMark = false;
        this.disabled = false;
      } else {
        this.$refs.upload.onError(response, file, fileList);
      }
    },
    error() {
      // 上传失败
      this.$message({
        message: "上传文件失败!",
        type: "error",
      });
      this.fileList = [];
      this.formData.file = "";
      this.disabled = false;
      this.loadingMark = false;
    },
    beforeRemove(file, fileList) {
      // 删除提示
      console.log(file, fileList);
    },
    handleRemove(file) {
      // 确认删除
      this.$refs.upload.abort(file);
      this.formData.file = "";
      this.disabled = false;
      this.loadingMark = false;
    },
  },
};
</script>

<style scoped lang="scss">
.createCrowdForm {
  display: flex;
  flex-direction: column;
  height: 100%;

  .formSubmit {
    .formInput {
      width: 70%;
      margin: 0 30px 0 0;
    }
  }

  .formTab {
    flex: 1;
    overflow: hidden;

    .checkbox {
      margin-left: 30px;
      width: 120px;
    }

    .el-radio {
      width: 120px;
      height: 30px;
      line-height: 30px;
      margin-left: 30px;
    }

    .uploadDiv {
      position: relative;
      // display: flex;
      // justify-content: space-around;
      // align-items: center;
      // margin-left: 30px;
      .loadingI {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translate(-50%, -50%);
        right: 100px;
      }
      + .annotation {
        margin-left: 30px;
      }
    }
  }
}
</style>

<style lang="scss">
.createCrowdForm {
  .el-progress__text {
    display: none;
    // width: 30px;
  }
}
</style>

<template>
  <el-dialog
    v-model="flag"
    title="新建人群"
    width="60%"
    custom-class="normalDialog"
    :close-on-click-modal="false"
    :before-close="initFormData"
    @open="openDialog"
    destroy-on-close
  >
    <el-form
      :model="formData"
      label-width="120px"
      class="createCrowdForm"
      ref="formData"
      :rules="rules"
      @submit.prevent
    >
      <!--当form里只有一个input的时候按回车会触发onsubmit事件-->
      <el-form-item label="人群名称" class="formSubmit" prop="name">
        <el-input v-model="formData.name" class="formInput" />
        <el-button size="small" type="primary" @click="onSubmit('formData')">
          创建
        </el-button>
      </el-form-item>
      <el-tabs type="card" v-model="activeTabName" class="formTab">
        <!--第一层-->
        <el-tab-pane label="人口属性">
          <el-form-item
            v-for="(item, i) in crowdProperty"
            :key="'formItem' + i"
          >
            <template #label>
              <!--城市只能单选-->
              <span v-if="item.keyValue === 'city'" style="margin-left: 25px">
                {{ item.label }}
              </span>
              <el-checkbox
                v-else
                :label="item.keyValue"
                :indeterminate="
                  checkBoxesAttribute[item.keyValue].isIndeterminate
                "
                v-model="checkBoxesAttribute[item.keyValue].checkAll"
                @change="
                  handleCheckAllChange(
                    checkBoxesAttribute[item.keyValue].checkAll,
                    item.keyValue,
                    item.values
                  )
                "
              >
                {{ item.label }}
              </el-checkbox>
            </template>

            <el-checkbox-group
              v-model="formData[item.keyValue]"
              @change="
                handleCheckedChange(
                  formData[item.keyValue],
                  item.keyValue,
                  item.values
                )
              "
            >
              <!--第三层-->
              <el-checkbox
                v-for="(checkbox, m) in item.values"
                :key="'checkbox' + m"
                :label="checkbox.value"
                class="checkbox"
              >
                {{ checkbox.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  getCrowdProperty,
  getInterest,
  createCrowd,
  checkCrowdName,
} from "@/api/createCrowd";

export default {
  name: "create-crowd",
  data() {
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入人群名称"));
      }
      this.checkCrowdName(value, callback);
    };
    return {
      formData: {
        name: "",
        interest: {},
      },
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
      },
      imgSrc: require("./add.png"),
      activeTabName: "0",
      checkBoxesAttribute: {
        interest: {},
      },
      crowdProperty: [],
      interest: [],
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "submit", "refreshCrowd"],
  computed: {
    flag: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    getCrowdProperty() {
      getCrowdProperty().then((res) => {
        this.formatCrowdProperty(res.data);
        this.crowdProperty = res.data;
      });
    },
    formatCrowdProperty(res) {
      for (let item of res) {
        this.formData[item.keyValue] = [];
        this.checkBoxesAttribute[item.keyValue] = {
          checkAll: false,
          isIndeterminate: false,
        };
      }
    },
    getInterest() {
      getInterest().then((res) => {
        this.formatInterest(res.data);
        this.interest = res.data;
      });
    },
    formatInterest(res) {
      for (let item of res) {
        this.formData.interest[item.keyValue] = [];
        this.checkBoxesAttribute.interest[item.keyValue] = {
          checkAll: false,
          isIndeterminate: false,
        };
      }
    },
    initFormData(done) {
      this.activeTabName = "0";
      this.formData.name = "";
      this.formData.interest = {};
      this.$refs.formData.resetFields();
      done();
    },
    openDialog() {
      this.formData.name = "";
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 把form表单数据格式化再提交
          this.formatFormData();
          console.log(this.formData);
          let tempFormData = JSON.parse(JSON.stringify(this.formData));
          Reflect.deleteProperty(tempFormData, "name");
          let isNoLabels = Object.values(tempFormData).every((v) => {
            return v.length === 0;
          });
          if (isNoLabels) {
            this.$message({
              type: "info",
              message: "未选择标签不能创建人群",
            });
            return;
          }
          createCrowd(this.formData).then((res) => {
            console.log(res);
            if (res.code === 0) {
              this.$message({
                type: "info",
                message: "正在创建请耐心等待!",
              });
              // 刷新crowdList
              this.$emit("update:modelValue", false);
              this.$emit("submit");
              this.$emit("refreshCrowd");
            } else if (res.code === 123) {
              this.$message.error("创建失败，请联系管理员");
            }
          });
        } else {
          return false;
        }
      });
    },
    handleCheckAllChange(bool, key, list, attr) {
      let formatList = this.formatList(list);
      if (!attr) {
        this.formData[key] = bool ? formatList : [];
        this.checkBoxesAttribute[key].isIndeterminate = false;
      } else {
        this.formData[attr][key] = bool ? formatList : [];
        this.checkBoxesAttribute[attr][key].isIndeterminate = false;
      }
    },
    handleCheckedChange(value, key, list, attr) {
      let formatList = this.formatList(list);
      let checkedCount = value.length;
      if (!attr) {
        this.checkBoxesAttribute[key].checkAll =
          checkedCount === formatList.length;
        this.checkBoxesAttribute[key].isIndeterminate =
          checkedCount > 0 && checkedCount < formatList.length;
      } else {
        this.checkBoxesAttribute[attr][key].checkAll =
          checkedCount === formatList.length;
        this.checkBoxesAttribute[attr][key].isIndeterminate =
          checkedCount > 0 && checkedCount < formatList.length;
      }
      // 城市变为单选
      if (key === "city") {
        this.formData[key] = value.slice(-1);
      }
    },
    formatList(list) {
      let tempList = list.map((item) => {
        return item.value;
      });
      return tempList;
    },
    formatFormData() {
      // 把interest数据加工
      let tempArr = Object.values(this.formData.interest);
      let arr = [];
      for (let item of tempArr) {
        if (item.length) {
          arr = arr.concat(item);
        }
      }
      this.formData.interest = arr;
    },
    checkCrowdName(name, callback) {
      let params = {
        name: name,
      };
      checkCrowdName(params).then((res) => {
        if (res.existCode) {
          callback(new Error("已存在相同名称人群"));
        } else {
          callback();
        }
      });
    },
  },
  watch: {
    flag(newVal) {
      if (newVal) {
        this.getCrowdProperty();
        this.getInterest();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.createCrowdForm {
  display: flex;
  flex-direction: column;
  height: 100%;

  .formSubmit {
    .formInput {
      width: 70%;
      margin: 0 30px 0 0;
    }
  }

  .formTab {
    flex: 1;
    overflow: hidden;

    .checkbox {
      margin-left: 30px;
      width: 120px;
    }

    .el-radio {
      width: 120px;
      height: 30px;
      line-height: 30px;
      margin-left: 30px;
    }

    .uploadDiv {
      margin-left: 30px;
    }
  }
}
</style>
